<template>
  <div>
    <b-row class="match-height">
      <b-col cols="6">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="ArchiveIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.complaints") }}</h5>
                    <h5 class="mb-0 t_1">{{ statistics.allComplaints }}</h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="6">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="ArrowRightIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.openComplaints") }}</h5>
                    <h5 class="mb-0 t_1">{{ statistics.openComplaints }}</h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="ClipboardIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.ServicesRequests") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ statistics.allSeriveRequests }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="6">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="ArrowRightIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.ServicesRequestsOpen") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ statistics.openSeriveRequests }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BMedia,
  },

  props: {
    statistics: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.t_1 {
  margin-top: 0.4rem;
}
</style>
