<template>
  <loading v-if="isLoading" />

  <div v-else>
    <b-row class="match-height">
      <b-col cols="12">
        <card :statistics="statistics" />
      </b-col>
      <b-col cols="12">
        <card-cohort :statistics="statistics" />
      </b-col>
      <b-col cols="12">
        <card-analytic-sale-line-chart :statistics="statistics" />
      </b-col>
      <b-col md="6">
        <card-analytic-sessions-by-device />
      </b-col>
      <b-col md="6">
        <card-analytic-customers />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import CardAnalyticSessionsByDevice from "./chart/CardAnalyticSessionsByDevice.vue";
import CardAnalyticCustomers from "./chart/CardAnalyticCustomers.vue";
import CardAnalyticSaleLineChart from "./chart/CardAnalyticSaleLineChart.vue";
import Card from "./card.vue";
import CardCohort from "./cardCohort.vue";
import Loading from "@/views/components/logic/loading.vue";

export default {
  components: {
    BRow,
    BCol,
    Loading,
    CardAnalyticSessionsByDevice,
    CardAnalyticCustomers,
    Card,
    CardAnalyticSaleLineChart,
    CardCohort,
  },

  data() {
    return {
      statistics: {},
      isLoading: true,
    };
  },

  created() {
    this.$http
      .get("admin/statistics")
      .then((res) => {
        if (res.status === 200) {
          this.statistics = res.data.data;
          this.isLoading = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
