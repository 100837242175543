<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body class="">
              <b-media-aside>
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="UsersIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.personnel") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.users }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="UsersIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.clients") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.clients }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="TruckIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.suppliers") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.suppliers }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CpuIcon" size="18" />
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h5 class="mb-0">{{ $t("g.equipment") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.devices }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="BriefcaseIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.Labs") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.labs }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="BriefcaseIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.samples") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.samples }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="PieChartIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.categories") }}</h5>
                <h5 class="mb-0 t_1">{{ statistics.categories }}</h5>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BMedia,
  },

  props: {
    statistics: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.t_1 {
  margin-top: 0.4rem;
}
</style>
